<div class="modal-header">
    <h5 class="modal-title">{{titulo}}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    </button>
</div>
<div class="modal-body">
    <p>{{texto}}</p>
    <br>
    <p>{{detalle}}</p>
</div>
<div class="modal-footer">
    <button *ngIf="!soloVolver" type="button" class={{class_boton}} (click)="action()">{{textoAceptar}}</button>
    <button type="button" class="btn btn-secondary" (click)="cancelar()">{{soloVolver ? 'Volver'
        : 'Cancelar'}}</button>
</div>