<div class="login-background">
	<div class="contenedor" [@crop]>
		<div class="izquierda">
			<div>
				<div class="label-intro my-4">
					<label>¡Hola de nuevo!</label>
				</div>
				<img src="/assets/images/login_image.svg" class="imagen">
				<div class="label-intro-2">
					<label>Bienvenido/a a nuestro sistema</label>
				</div>
			</div>
		</div>
		<div class="derecha">
			<div class="formulario">
				<form [formGroup]="loginForm">
					<div class="logo">
						<img src="./../../../assets/system/logo_login.png" alt="Logo">
					</div>
					<div class="label-instruccion">
						<label>Inicia sesión y mantén tu negocio en orden</label>
					</div>
					<div>
						<label for="email" class="label-input">Correo Electrónico</label>
						<input type="text" class="form-control" id="email" formControlName="email"
							[ngClass]="{'is-invalid': loginForm.controls['email'].errors && (loginForm.controls['email'].dirty || loginForm.controls['email'].touched)}">
						<div class="invalid-feedback mb-3" *ngIf=" loginForm.controls['email'].errors?.['required']">
							Ingrese un correo electrónico.
						</div>
						<div class="invalid-feedback mb-3" *ngIf=" loginForm.controls['email'].errors?.['email']">
							El correo electrónico no es válido.
						</div>
						<div class="mb-3"
							*ngIf="!loginForm.controls['email'].errors || !(loginForm.controls['email'].dirty || loginForm.controls['email'].touched)">
							<div>&nbsp;</div>
						</div>
					</div>

					<div>
						<label class=" d-flex justify-content-between align-items-center" for="password"><label class="label-input">Contraseña</label><a
								type="button" (click)="recuperarContrasena()">¿Olvidó su contraseña?</a></label>

						<input type="password" class="form-control" id="password" formControlName="password"
							[ngClass]="{'is-invalid': loginForm.controls['password'].errors && loginForm.controls['password'].touched}">
						<div class="invalid-feedback mb-3">
							Ingrese una contraseña.
						</div>
						<div class="mb-3"
							*ngIf="!loginForm.controls['password'].errors || !(loginForm.controls['password'].dirty || loginForm.controls['password'].touched)">
							<div>&nbsp;</div>
						</div>

					</div>

					<div class="d-flex flex-column w-100 my-4" style="gap: .5rem">
						<button type="button" class="btn btn-login shadow-sm"
							style="font-weight: bold;font-family: Helvetica, Arial, sans-serif;font-size: 16px"
							(click)="iniciarSesion()">Iniciar Sesión</button>
					</div>
					<br>
					<br>
					<br>
					<br>
				</form>
			</div>
		</div>
	</div>
</div>