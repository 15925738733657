import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './utils/login/login.component';
import { AuthGuard } from './utils/auth/auth.guard';
import { HomeComponent } from './components/home/home.component';
import { TosComponent } from './utils/tos/tos/tos.component';
import { NuevaContrasenaComponent } from './utils/nueva-contrasena/nueva-contrasena.component';
import { RecuperarContrasenaComponent } from './utils/recuperar-contrasena/recuperar-contrasena.component';
import { SoporteComponent } from './utils/soporte/soporte/soporte.component';

const routes: Routes = [
	{ path: 'reportes', loadChildren: () => import('./components/reportes/reportes.module').then(m => m.ReportesModule) },
  { path: 'login', component: LoginComponent },
  { path: 'soporte', component: SoporteComponent },
  { path: 'terminos', component: TosComponent },
  { path: 'recuperar-password', component: RecuperarContrasenaComponent },
  { path: 'restablecer/:token', component: NuevaContrasenaComponent },
  // Rutas Protegidas
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3] } },
  { path: 'clientes', loadChildren: () => import('./components/cliente/cliente.module').then(m => m.ClienteModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3] } },
  { path: 'usuarios', loadChildren: () => import('./components/usuario/usuario.module').then(m => m.UsuarioModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3] } },
  { path: 'vehiculos', loadChildren: () => import('./components/vehiculo/vehiculo.module').then(m => m.VehiculoModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3] } },
  { path: 'revisiones', loadChildren: () => import('./components/revision/revision.module').then(m => m.RevisionModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3] } },
  { path: 'publicidades', loadChildren: () => import('./components/publicidad/publicidad.module').then(m => m.PublicidadModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2, 3] } },
  { path: 'tipo-servicio', loadChildren: () => import('./components/tipo-revision/tipo-revision.module').then(m => m.TipoRevisionModule), canActivate: [AuthGuard], data: { expectedRoles: [1, 2] } },
  { path: 'actualizacion-stock', loadChildren: () => import('./components/actualizacion-stock/actualizacion-stock.module').then(m => m.ActualizacionStockModule) },
  { path: 'tipo-actualizacion', loadChildren: () => import('./components/tipo-actualizacion/tipo-actualizacion.module').then(m => m.TipoActualizacionModule) },
  { path: 'tipo-gastos', loadChildren: () => import('./components/tipo-gastos/tipo-gastos.module').then(m => m.TipoGastosModule) },
  { path: 'gastos', loadChildren: () => import('./components/gastos/gastos.module').then(m => m.GastosModule) },
  { path: 'proveedor', loadChildren: () => import('./components/proveedor/proveedor.module').then(m => m.ProveedorModule) },
  { path: 'producto', loadChildren: () => import('./components/producto/producto.module').then(m => m.ProductoModule) },
  { path: 'compra', loadChildren: () => import('./components/compra/compra.module').then(m => m.CompraModule) },
  { path: 'categoria', loadChildren: () => import('./components/categoria/categoria.module').then(m => m.CategoriaModule) },
  { path: 'servicio', loadChildren: () => import('./components/servicio/servicio.module').then(m => m.ServicioModule) },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
