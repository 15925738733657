import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SesionService } from 'src/app/utils/sesion/sesion.service';
import { UsuarioService } from '../usuario.service';

@Component({
  selector: 'app-usuario-profile',
  templateUrl: './usuario-profile.component.html',
  styleUrls: ['./usuario-profile.component.scss'],
  animations: [
    trigger('dialog', [
      transition('void => *', [
        style({ transform: 'scale3d(.3, .3, .3)' }),
        animate(100)
      ]),
      transition('* => void', [
        animate(100, style({ transform: 'scale3d(.0, .0, .0)' }))
      ])
    ])
  ]
})
export class UsuarioProfileComponent implements OnInit {

  @Input() element = null;
  form: FormGroup = this.formBuilder.group({
    nombre: ['', [Validators.required]],
    apellido: ['', [Validators.required]],
    email: ['', [Validators.required]],
    fono: [''],
    rol_id: ['', [Validators.required]],
  });

  constructor(
    public formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private sesion: SesionService,
    private service: UsuarioService
  ) { }

  ngOnInit() {
    this.form.disable();
    this.service.view(this.sesion.id).subscribe({
      next: (res) => {
        if (res) {
          this.form.patchValue(res);
        }
      }
    });
  }
}
