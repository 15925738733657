import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MarkdownService } from 'ngx-markdown';

@Component({
  selector: 'app-soporte',
  templateUrl: './soporte.component.html',
  styleUrls: ['./soporte.component.scss']
})
export class SoporteComponent implements OnInit {

  md: string = '';
  constructor(
    private http: HttpClient,
    private mdService: MarkdownService,
  ) { }

  ngOnInit(): void {
    this.loadToS();
  }

  async loadToS() {
    this.http.get(`/assets/soporte/soporte.md`,
      { responseType: 'text' }).subscribe({
        next: (res) => {
          this.md =this.mdService.compile(res);
        },
        error: (err) => {
          console.error(err);
        }
      });
  }
}
