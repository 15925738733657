<kuv-alert></kuv-alert>
<kuv-loading></kuv-loading>
<div class="container-fluid p-5">

    <div class="flex-container">
        <div class="flex-item-left">
            <h4 class="titulo"><b>Datos Mensuales</b></h4>
            <hr>
            <div class="flex-container-indicadores">
                <div class="flex-item-indicadores-left">
                    <label class="indicador">Nuevos Clientes</label>
                    <h6 class="indicador-numero">{{datos_mensuales.nuevos_clientes}}</h6>
                </div>
                <div class="flex-item-indicadores-center">
                    <label class="indicador">Clientes Activos</label>
                    <h6 class="indicador-numero">{{datos_mensuales.clientes_activos}}</h6>
                </div>
                <div class="flex-item-indicadores-right">
                    <label class="indicador">Servicios Registrados</label>
                    <h6 class="indicador-numero">{{datos_mensuales.servicios_registrados}}</h6>
                </div>
            </div>
            <div>
                <div class="chart" id="chart_mensual" style="position: relative; width: 100%; height: 450px; padding: 0px; margin: 0px; border-width: 0px; cursor: default;"></div>
            </div>
        </div>
        <div class="flex-item-right">
            <h4 class="titulo"><b>Datos Anuales</b></h4>
            <hr>
            <div class="flex-container-indicadores">
                <div class="flex-item-indicadores-left">
                    <label class="indicador">Nuevos Clientes</label>
                    <h6 class="indicador-numero">{{datos_anuales.nuevos_clientes}}</h6>
                </div>
                <div class="flex-item-indicadores-center">
                    <label class="indicador">Clientes Activos</label>
                    <h6 class="indicador-numero">{{datos_anuales.clientes_activos}}</h6>
                </div>
                <div class="flex-item-indicadores-right">
                    <label class="indicador">Servicios Registrados</label>
                    <h6 class="indicador-numero">{{datos_anuales.servicios_registrados}}</h6>
                </div>
            </div>
            <div>
                <div class="chart" id="chart_anual" style="position: relative; width: 100%; height: 450px; padding: 0px; margin: 0px; border-width: 0px; cursor: default;"></div>
            </div>
        </div>
    </div>
</div>