<div class="modal-header">
	<h4 class="modal-title">Cambiar Contraseña</h4>
	<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
	</button>
</div>
<div class="modal-body mx-3 my-1">
	<form [formGroup]="form">
		<div class="form-group">
			<label for="oldPass">Contraseña Actual</label>
			<input type="password" autocomplete="off" class="form-control" id="oldPass" formControlName="oldPass"
				[ngClass]="{'is-invalid': f['oldPass'].errors && f['oldPass'].touched}">
			<div *ngIf="f['oldPass'].errors" class="invalid-feedback">
				<div *ngIf="f['oldPass'].errors['required']">La contraseña no puede ser vacía.</div>
			</div>
			<div class="mb-4" *ngIf="!f['oldPass'].errors || !f['oldPass'].touched">
			</div>
		</div>
		<div class="form-group">
			<label for="newPass">Nueva Contraseña</label>
			<input type="password" autocomplete="off" class="form-control" id="newPass" formControlName="newPass"
				[ngClass]="{'is-invalid': f['newPass'].errors && f['newPass'].touched}">
			<div *ngIf="f['newPass'].errors" class="invalid-feedback">
				<div *ngIf="f['newPass'].errors['required']">La contraseña no puede ser vacía.</div>
			</div>
			<div class="mb-4" *ngIf="!f['newPass'].errors || !f['newPass'].touched">
			</div>
		</div>
		<div class="form-group">
			<label for="repeatPass">Repetir Contraseña</label>
			<input type="password" autocomplete="off" class="form-control" id="repeatPass" formControlName="repeatPass"
				[ngClass]="{'is-invalid': f['repeatPass'].errors && f['repeatPass'].touched}">
			<div *ngIf="f['repeatPass'].errors" class="invalid-feedback">
				<div *ngIf="f['repeatPass'].errors['required']">La contraseña no puede ser vacía.</div>
				<div *ngIf="f['repeatPass'].errors['mustMatch']">Las contraseñas no coinciden.</div>
			</div>
			<div class="mb-4" *ngIf="!f['repeatPass'].errors || !f['repeatPass'].touched">
			</div>
		</div>
	</form>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-success" (click)="guardar()">Guardar</button>
	<button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('Cancel click')">Cancelar</button>
</div>