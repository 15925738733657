import { Injectable } from '@angular/core';
import { AlertService, LoadingService } from 'ngx-kuv-tools';
import { SesionService } from '../sesion/sesion.service';

@Injectable({
  providedIn: 'root'
})
export class FormatterService {

  constructor(
    private sesion: SesionService,
    private alerts: AlertService,
    private loading: LoadingService
  ) { }

  /**
   * Cambia el formato de una fecha de "yyyy-mm-dd HH:ii:ss" a "dd-mm-yyyy HH:ii:ss"
   */
  timestampFormat(fecha: string) {
    if (fecha) {
      return fecha.substr(8, 2) + "-" + fecha.substr(5, 2) + "-" + fecha.substr(0, 4) + " " + fecha.substr(11, 8);
    } else {
      return fecha
    }
  }
  /**
   * Cambia el formato de una fecha de "yyyy-mm-dd" a "dd-mm-yyyy"
   */
  dateFormat(fecha: string) {
    if (fecha) {
      return fecha.substr(8, 2) + "-" + fecha.substr(5, 2) + "-" + fecha.substr(0, 4)
    } else {
      return fecha
    }
  }
  /**
   * Cambia el formato de una fecha de "dd-mm-yyyy" a "yyyy-mm-dd"
   */
  usaDateFormat(fecha: string) {
    if (fecha) {
      return fecha.substr(6, 4) + "-" + fecha.substr(3, 2) + "-" + fecha.substr(0, 2)
    } else {
      return fecha
    }
  }

  /**
    * Valida si el valor del carácter presionado es un número mayor o igual a cero.
    *
    * @param {KeyboardEvent} event - El evento del teclado.
    */
  validatePositiveNumber(event: KeyboardEvent) {
    // Obtener el valor del carácter presionado
    const char = event.key;

    // Verificar si el valor es un número mayor o igual a cero
    if (char.match(/[0-9]/) && Number(char) >= 0) {
      // El valor es válido, puedes procesarlo como desees
    } else {
      // El valor es inválido, puedes mostrar un mensaje de error o impedir que se ingrese
      event.preventDefault();
    }
  }

  /**
 * Valida si el valor del carácter presionado es un número.
 *
 * @param {KeyboardEvent} event - El evento del teclado.
 */
  validateAllNumber(event: KeyboardEvent) {
    // Obtener el valor del carácter presionado
    const char = event.key;

    // Verificar si el valor es un número o el signo -
    if (char.match(/[0-9-]/)) {
      // El valor es válido, puedes procesarlo como desees
    } else {
      // El valor es inválido, puedes mostrar un mensaje de error o impedir que se ingrese
      event.preventDefault();
    }
  }


  // Funciona independiente del largo
  formatoRUT(rut: string) { // String con formato "12345678k"
    if (!rut) return '';
    let digit = rut.slice(-1);
    let num = rut.slice(0, -1);
    let result = '';
    while (num.length > 0) {
      if (result != '') result = '.' + result;
      result = num.slice(-3) + result;
      num = num.slice(0, -3)
    }
    return result + '-' + digit;
  }

  /**
   * Retorna la concatencación de nombre y apellido
   * @param persona Object el objeto que tiene nombre y apellido
   * @param nombre string el identificador del nombre
   * @param apellido string el identificador del apellido
   * @returns 
   */
  nombreCompleto(persona: any, nombre = "nombre", apellido = "apellido") {
    if (!persona) return "";
    if (!persona[apellido]) return persona[nombre];
    return persona[nombre] + " " + persona[apellido];
  }

  esRol(expectedRol: number) {
    let rol = this.sesion.rol;
    return rol == 1 || rol == 2 || rol == expectedRol;
  }

  esRolExclusivo(expectedRol: number) {
    let rol = this.sesion.rol;
    return rol == expectedRol;
  }

  splitString(text: string, length: number = 100, limit: number = 100): Array<string> {
    if (!text) return [''];
    if (limit == 0) return [''];
    if (text.length < length) return [text];
    return [text.substr(0, length)].concat(this.splitString(text.substr(length), length, limit--));
  }

  capitalize(texto: string) {
    return texto.substring(0, 1).toUpperCase() + texto.substring(1);
  }

  stringToComparableNumber(text: string, length: number = 10) {
    let maxCode = '000';
    // if (!text) return new Array(length).fill(maxCode).join('');
    if (!text) text = '0';
    let array = text.split('');
    if (array.length < length) array = new Array(length - array.length).fill('0').concat(array);
    let indexOfMin = array.findIndex(e => e == '-');
    if (indexOfMin != -1 && indexOfMin != 0) {
      let aux = array[indexOfMin];
      array.splice(indexOfMin, 1);
      array = [aux].concat(array);
    }
    let inverse = 0;
    array = array.map(e => {
      let res = '';
      if (e == '-') {
        inverse = 999;
        return '000';
      }
      res = Math.abs(inverse - e.charCodeAt(0)).toString();
      let aux = res.split('');
      if (aux.length < 3) aux = new Array(3 - aux.length).fill('0').concat(aux);
      return aux.join('');
    });
    return array.join('');
  }

  /**
   * 
   * @param num Número sin formato
   * @param minDecimal Cantidad mínima de decimales, default 0
   * @param maxDecimal Cantidad máxima de decimales, default 2
   * @param errMsg Texto en caso de error
   * @returns El número con formato 00.000.000,00
   */
  numberFormat(num: number, minDecimal: number = 0, maxDecimal: number = 2, errMsg: string = 'N.D.') {
    try {
      return (num-0).toLocaleString('es-CL', { minimumFractionDigits: minDecimal, maximumFractionDigits: maxDecimal });
    } catch (error) {
      return errMsg;
    }
  }

  showErrorAlerts(error: any) {
    if (error.response) this.alerts.addAlert(error.response, 'danger');
    if (error.errors) {
      Object.keys(error.errors).forEach(key => {
        error.errors[key].forEach((element: any) => {
          this.alerts.addAlert(element, 'danger');
        });
      })
    }
  }

  /**
   * Valida si el valor del carácter presionado es un número mayor o igual a cero.
   *
   * @param {KeyboardEvent} event - El evento del teclado.
   */
  validateNumber(event: KeyboardEvent) {
    // Obtener el valor del carácter presionado
    const char = event.key;

    // Verificar si el valor es un número mayor o igual a cero
    if (char.match(/[0-9]/) && Number(char) >= 0) {
      // El valor es válido, puedes procesarlo como desees
    } else {
      // El valor es inválido, puedes mostrar un mensaje de error o impedir que se ingrese
      event.preventDefault();
    }
  }

  /**
   * Convierte un arreglo de elementos en 2 arreglos para la leyenda y los valores de un reporte por fecha
   * @param data Arreglo de elementos con {dia, mes, anio y total}
   * @param desde desde que fecha
   * @param hasta hasta que fecha
   * @param tipoVista 1-Dia, 2-Mes, 3-Año
   * @param valor El nombre del parámetro que tiene el valor para el resultado
   * @returns {leyenda: string[], result: number[]} leyenda representa las fechas y result los valores
   */
  parseDataParaReporte(data: any, desde: string, hasta: string, tipoVista: number, valor: string = 'total', mantenerValor: boolean = false) {
    let leyenda: any[] = [];
    let result: any[] = [];
    // Definir límites
    let desdeDate = new Date(desde);
    let hastaDate = new Date(hasta);
    let index = 0; // Para recorrer la data
    let lastTotal = 0;
    while (desdeDate < hastaDate) {
      let total = 0;
      let cantidadElementos = 0;
      // Definir Variables
      let anio = desdeDate.getFullYear();
      let mes = (desdeDate.getMonth() - 0) + 1;
      let dia = desdeDate.getDate();
      // Asignar leyenda
      let legend = `${anio}`;
      if (tipoVista == 1) legend = `${dia < 10 ? '0' + dia : dia}-${mes < 10 ? '0' + mes : mes}-${anio}`
      else if (tipoVista == 2) legend = `${mes < 10 ? '0' + mes : mes}-${anio}`
      // Obtengo el elemento actual
      let element = data[index];
      // Defino la consulta a utilizar
      let consulta = false;
      if (element) {
        consulta = element.anio == anio;
        if (tipoVista == 1) consulta = element.anio == anio && element.mes == mes && element.dia == dia;
        else if (tipoVista == 2) consulta = element.anio == anio && element.mes == mes;
      }
      // Mientras la consulta sea válida, se recorre la data
      while (consulta) {
        total += (element[valor] - 0);
        cantidadElementos++;
        index++;
        element = data[index]; // Obtengo el elemento actual
        consulta = false;
        if (element) {
          consulta = element.anio == anio;
          if (tipoVista == 1) consulta = element.anio == anio && element.mes == mes && element.dia == dia;
          else if (tipoVista == 2) consulta = element.anio == anio && element.mes == mes;
        }
      }
      leyenda.push(legend);
      if(cantidadElementos == 0 && mantenerValor) total = lastTotal;
      result.push(total);
      lastTotal = total;
      mes--;
      if (tipoVista == 1) dia++;
      else if (tipoVista == 2) mes++;
      else anio++;
      desdeDate = new Date(anio, mes, dia, 12, 0, 0);
    }

    return { leyenda, result }
  }

  obtenerFiltroFecha(tipo: any = null) {
    let dateHoy = new Date();
    let dia = dateHoy.getDate();
    let mes = (dateHoy.getMonth() - 0) + 1;
    let anio = dateHoy.getFullYear();
    let hora = dateHoy.getHours();
    let minuto = dateHoy.getMinutes();
    let filtroFecha = tipo;
    let desde: string = '';
    let hasta: string = '';
    switch (tipo) {
      case 1:

        break;
      case 2:
        desde = `${anio}-${mes < 10 ? '0' + mes : mes}-${dia < 10 ? '0' + dia : dia} 00:00`
        hasta = `${anio}-${mes < 10 ? '0' + mes : mes}-${dia < 10 ? '0' + dia : dia} 23:59`
        break;
      case 3:
        desde = `${anio}-${mes < 10 ? '0' + mes : mes}-01 00:00`
        hasta = `${new Date(anio, mes, 0, 12, 0, 0).toISOString().substring(0, 10)} 23:59`
        break;
      case 4:
        desde = `${anio}-${mes - 1 < 10 ? '0' + (mes - 1) : mes - 1}-01 00:00`
        hasta = `${new Date(anio, mes - 1, 0, 12, 0, 0).toISOString().substring(0, 10)} 23:59`
        break;
      case 5:
        desde = `${anio}-01-01 00:00`
        hasta = `${anio}-12-31 23:59`
        break;
      case 6:
        desde = `${anio - 1}-01-01 00:00`
        hasta = `${anio - 1}-12-31 23:59`
        break;
      case 7:
        desde = `1970-01-01 00:00`
        hasta = `2099-12-31 23:59`
        break;
      default:
        break;
    }

    return {desde, hasta};
  }
}
