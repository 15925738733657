<div class="modal-header">
    <h4 class="modal-title">Mi Perfil</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-12 col-sm-12 mb-2">
                <label for="email">Correo Electrónico</label>
                <input type="email" class="form-control" id="email" formControlName="email"
                    [ngClass]="{'is-invalid': form.controls['email'].errors && form.controls['email'].touched}">
                <div class="invalid-feedback">
                    Debe ingresar un valor en el campo
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-sm-6 mb-2">
                <label for="nombre">Nombre</label>
                <input type="text" class="form-control" id="nombre" formControlName="nombre"
                    [ngClass]="{'is-invalid': form.controls['nombre'].errors && form.controls['nombre'].touched}">
                <div class="invalid-feedback">
                    Debe ingresar un valor en el campo
                </div>
            </div>
            <div class="col-12 col-sm-6 mb-2">
                <label for="apellido">Apellido</label>
                <input type="text" class="form-control" id="apellido" formControlName="apellido"
                    [ngClass]="{'is-invalid': form.controls['apellido'].errors && form.controls['apellido'].touched}">
                <div class="invalid-feedback">
                    Debe ingresar un valor en el campo
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-sm-6 mb-2">
                <label for="rol_id">Rol</label>
                <select class="form-select" id="rol_id" formControlName="rol_id"
                    [ngClass]="{'is-invalid': form.controls['rol_id'].errors && form.controls['rol_id'].touched}">
                    <option value="1">Administrador Kuvemar</option>
                    <option value="2">Administrador</option>
                    <option value="3">Usuario</option>
                    <option value="4">Usuario App</option>
                </select>
            </div>
            <div class="col-12 col-sm-6 mb-2">
                <label for="fono">Teléfono</label>
                <input type="text" class="form-control" id="fono" formControlName="fono">
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('Cancel click')">Cerrar</button>
</div>