import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/utils/config/config.service';
import { SesionService } from 'src/app/utils/sesion/sesion.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  controller: string = "reporte/";

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private sesion: SesionService
  ) { }

  getIndicadores(): Observable<any> {
    return this.http.get(this.config.apiUrl + this.controller + 'indicadores-inicio', this.sesion.headers);
  }
}
