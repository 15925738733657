import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService, AlertService } from 'ngx-kuv-tools';
import { SesionService } from '../sesion/sesion.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../modal/modal.component';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-nueva-contrasena',
  templateUrl: './nueva-contrasena.component.html',
  styleUrls: ['./nueva-contrasena.component.scss'],
  animations: [
    trigger('slide', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('.5s ease-out', style({ transform: 'translateX(0%)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate('.5s ease-in',
          style({ transform: 'translateX(-100%)', opacity: 0 }))
      ])
    ]),
    trigger('crop', [
      transition(':enter', [
        style({ transform: 'scale(120%)', opacity: 0 }),
        animate('.5s ease-out', style({ transform: 'scale(100%)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'scale(100%)', opacity: 1 }),
        animate('.5s ease-in',
          style({ transform: 'scale(120%)', opacity: 0 }))
      ])
    ]),
    trigger('slideup', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('.5s ease-out', style({ transform: 'translateY(0%)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0%)', opacity: 1 }),
        animate('.5s ease-in',
          style({ transform: 'translateY(-100%)', opacity: 0 }))
      ])
    ])
  ]
})
export class NuevaContrasenaComponent implements OnInit {

  form: FormGroup = new FormGroup({});

  pwdCambiado: boolean = false;
  pwdCambioTerminado: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private service: SesionService,
    private loading: LoadingService,
    private alerts: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      newPass: ['', Validators.required],
      repeatPass: ['', Validators.required],
      token: ['', [Validators.required]],
    }, {
      validator: this.mustMatch('newPass', 'repeatPass')
    });
    this.form.patchValue({ token: this.route.snapshot.params['token'] });
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
        return;
      }
      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  iniciarSesion(): void {
    this.router.navigate(['login']);
  }

  recuperarContrasena(): void {
    if (this.form.valid) {
      this.loading.show();
      this.service.restablecer(this.form.value).subscribe({
        next: (res) => {
          this.loading.hide();
          this.pwdCambiado = true;
          setTimeout(() => {
            this.pwdCambioTerminado = true;
          }, 800);
        },
        error: (err) => {
          console.error(err);
          this.loading.hide();
          if (err.error.response) {
            let modalRef = this.modalService.open(ModalComponent, { size: 'md', scrollable: true, backdrop: 'static' });
            modalRef.componentInstance.titulo = 'Error de Recuperación de Cuenta'
            modalRef.componentInstance.texto = `Ocurrió un error al actualizar su contraseña.`;
            modalRef.componentInstance.detalle = err.error.response;
            modalRef.componentInstance.soloVolver = true;
            modalRef.result.then((result: any) => { }, (reason: any) => { });
            return;
          }
          if (err.status == 401) {
            let modalRef = this.modalService.open(ModalComponent, { size: 'md', scrollable: true, backdrop: 'static' });
            modalRef.componentInstance.titulo = 'Error de Recuperación de Cuenta'
            modalRef.componentInstance.texto = `El enlace de recuperación de contraseña ha expirado.`;
            modalRef.componentInstance.detalle = '';
            modalRef.componentInstance.soloVolver = true;
            modalRef.result.then((result: any) => { }, (reason: any) => { });
            return;
          }
          this.alerts.addAlert("No se ha podido realizar el cambio de contraseña.", "danger");
        }
      })
    } else {
      this.form.markAllAsTouched();
    }
  }
}
