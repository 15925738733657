import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingService, AlertService } from 'ngx-kuv-tools';
import { SesionService } from 'src/app/utils/sesion/sesion.service';
import { ModalComponent } from '../modal/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-recuperar-contrasena',
  templateUrl: './recuperar-contrasena.component.html',
  styleUrls: ['./recuperar-contrasena.component.scss'],
  animations: [
    trigger('slide', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('.5s ease-out', style({ transform: 'translateX(0%)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate('.5s ease-in',
          style({ transform: 'translateX(-100%)', opacity: 0 }))
      ])
    ]),
    trigger('crop', [
      transition(':enter', [
        style({ transform: 'scale(120%)', opacity: 0 }),
        animate('.5s ease-out', style({ transform: 'scale(100%)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'scale(100%)', opacity: 1 }),
        animate('.5s ease-in',
          style({ transform: 'scale(120%)', opacity: 0 }))
      ])
    ]),
    trigger('slideup', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('.5s ease-out', style({ transform: 'translateY(0%)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0%)', opacity: 1 }),
        animate('.5s ease-in',
          style({ transform: 'translateY(-100%)', opacity: 0 }))
      ])
    ])
  ]
})
export class RecuperarContrasenaComponent implements OnInit {

  form: FormGroup = new FormGroup({});

  emailEnviado: boolean = false;
  procesoTerminado: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private service: SesionService,
    private loading: LoadingService,
    private alerts: AlertService,
    private router: Router,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      fecha: [null],
    });
  }

  iniciarSesion(): void {
    this.router.navigate(['login']);
  }

  recuperarContrasena(): void {
    if (this.form.valid) {
      this.loading.show();
      this.form.patchValue({ fecha: new Date() });
      this.service.recuperar(this.form.value).subscribe({
        next: (res) => {
          this.loading.hide();
          this.emailEnviado = true;
          setTimeout(() => {
            this.procesoTerminado = true;
          }, 800);
        },
        error: (err) => {
          console.error(err);
          this.loading.hide();
          if (err.error.response) {
            let modalRef = this.modalService.open(ModalComponent, { size: 'md', scrollable: true, backdrop: 'static' });
            modalRef.componentInstance.titulo = 'Error de Recuperación de Cuenta'
            modalRef.componentInstance.texto = `Ocurrió un error al solicitar la recuperación de contraseña.`;
            modalRef.componentInstance.detalle = err.error.response;
            modalRef.componentInstance.soloVolver = true;
            modalRef.result.then((result: any) => { }, (reason: any) => { });
            return;
          }
          if (err.error.msg) {
            this.alerts.addAlert(err.error.msg, "danger");
            return;
          }
          if (err.error && err.error.errors[0]) {
            this.alerts.addAlert(err.error.errors[0].msg, "danger");
            return;
          }
          this.alerts.addAlert("No se ha podido realizar la solicitud de recuperación de cuenta.", "danger");
        }
      });
    } else {
      this.form.markAllAsTouched();
    }
  }
}
