<div class="logos d-flex justify-content-center align-items-center flex-column mb-3" style="gap: 1rem;">
    <div class="logo" style="width: 250px; max-height: 180px; margin-left: 0px">
        <img src="./../../../assets/system/logo.png" alt="logo" style="max-width: 100%; max-height: 100%;">
    </div>
    <div class="texto d-block shadow">
        <markdown [data]="md"></markdown>
    </div>
    
</div>
<div class="contenedor">
</div>