import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService, LoadingService } from 'ngx-kuv-tools';
import { SesionService } from 'src/app/utils/sesion/sesion.service';
import { FormatterService } from '../formatter/formatter.service';
import { SucursalService } from 'src/app/components/sucursal/sucursal.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('slide', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('.5s ease-out', style({ transform: 'translateX(0%)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate('.5s ease-in',
          style({ transform: 'translateX(-100%)', opacity: 0 }))
      ])
    ]),
    trigger('crop', [
      transition(':enter', [
        style({ transform: 'scale(120%)', opacity: 0 }),
        animate('.5s ease-out', style({ transform: 'scale(100%)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'scale(100%)', opacity: 1 }),
        animate('.5s ease-in',
          style({ transform: 'scale(120%)', opacity: 0 }))
      ])
    ]),
    trigger('slideup', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('.5s ease-out', style({ transform: 'translateY(0%)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0%)', opacity: 1 }),
        animate('.5s ease-in',
          style({ transform: 'translateY(-100%)', opacity: 0 }))
      ])
    ])
  ]
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup = new FormGroup({});

  constructor(
    private formBuilder: FormBuilder,
    private loading: LoadingService,
    private alerts: AlertService,
    private router: Router,
    private formatter: FormatterService,
    private sesion: SesionService,
    private sucursalService: SucursalService,
  ) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      password: [null, [Validators.required]],
      email: [null, Validators.required]
    });
    this.isLogged();
  }

  iniciarSesion(): void {
    if (this.loginForm.valid) {
      this.loading.show();
      this.sesion.login(this.loginForm.value).subscribe({
        next: (res) => {
          this.getSucursales();
        },
        error: (err) => {
          console.error(err);
          this.loading.hide();
          this.alerts.addAlert("No se ha podido iniciar sesión.", "danger");
        }
      });
    }
    else {
      this.loginForm.markAllAsTouched();
      this.alerts.addAlert("Hay errores en el formulario.", "warning");
    }
  }

  recuperarContrasena(): void {
    this.router.navigate(['recuperar-password']);
  }

  isLogged(): void {
    if (window.location.pathname === '/terminos') return;
    if (window.location.pathname === '/recuperar-password') return;
    if (window.location.pathname.includes('/restablecer')) return;
    if (this.sesion.token) {
      this.loading.show();
      this.sesion.isLogged().subscribe({
        next: (res) => {
          this.sesion.saveSesion(this.sesion.token ? this.sesion.token : res.token, res.usuario);
          this.loading.hide();
          this.router.navigate(['/']);
          this.alerts.addAlert("Bienvenido de nuevo " + res.usuario.nombre, "info");
        },
        error: (err) => {
          console.error(err);
          this.sesion.clearSesion();
          this.router.navigate(['login']);
          this.loading.hide();
          this.alerts.addAlert("No se ha podido reiniciar sesión.", "danger");
        }
      });
    } else {
      this.router.navigate(['login']);
    }
  }

  getSucursales(): void {
    if (!this.sesion.token) return;
    this.sucursalService.misSucursales().subscribe({
      next: (res) => {
        this.sesion.sucursales = res.sucursales;
        if (this.sesion.sucursales.length != 0) {
          if (this.sesion.sucursal?.id != this.sesion.sucursales[0].id) {
            localStorage.setItem('sucursal', JSON.stringify(this.sesion.sucursales[0]));
            localStorage.setItem('sucursal_id', `${this.sesion.sucursales[0].id}`);
            this.sesion.sucursal = this.sesion.sucursales[0];
            let sucursal = this.sesion.sucursal;
            this.sesion.sucursalIndex = this.sesion.sucursales.findIndex(s => s.id == sucursal.id);
          } else {
            let sucursal = this.sesion.sucursal;
            this.sesion.sucursalIndex = this.sesion.sucursales.findIndex(s => s.id == sucursal.id);
          }
        }
        this.router.navigate(['home']);
        this.loading.hide();
        this.alerts.addAlert("Bienvenido " + this.formatter.nombreCompleto(this.sesion.user), "success");
      },
      error: (err) => {
        console.error(err);
        this.sesion.clearSesion();
        this.loading.hide();
      }
    });
  }
}
