<div class="login-background">
	<div *ngIf="!pwdCambiado" class="contenedor" [@crop]>
		<div class="izquierda">
			<div>
				<div class="label-intro">
					<label>Restablecer Contraseña</label>
					<br>
					<label>Lubricentro</label>
				</div>
				<img src="/assets/images/pwd-recovery.svg" class="imagen">
				<div class="label-intro-2">
					<label></label>
				</div>
			</div>
		</div>
		<div class="derecha">
			<div class="formulario">
				<form [formGroup]="form">
					<div class="logo">
						<img src="./../../../assets/system/logo_login.png" alt="Logo">
					</div>
					<div class="label-instruccion">
						<label>Ingrese una nueva contraseña para su cuenta de Lubricentro </label>
					</div>
					<div>
						<label for="newPass" class="label-input">Nueva Contraseña</label>
						<input type="password" class="form-control" id="newPass" formControlName="newPass"
							[ngClass]="{'is-invalid': form.controls['newPass'].errors && (form.controls['newPass'].dirty || form.controls['newPass'].touched)}">
						<div class="invalid-feedback mb-3" *ngIf=" form.controls['newPass'].errors?.['required']">
							Debe ingresar una nueva contraseña.
						</div>
						<div class="mb-3"
							*ngIf="!form.controls['newPass'].errors || !(form.controls['newPass'].dirty || form.controls['newPass'].touched)">
							<div>&nbsp;</div>
						</div>
					</div>

					<div>
						<label for="repeatPass" class="label-input">Repita la Nueva Contraseña</label>
						<input type="password" class="form-control" id="repeatPass" formControlName="repeatPass"
							[ngClass]="{'is-invalid': form.controls['repeatPass'].errors && (form.controls['repeatPass'].dirty || form.controls['repeatPass'].touched)}">
						<div class="invalid-feedback mb-3" *ngIf=" form.controls['repeatPass'].errors?.['required']">
							Debe ingresar una nueva contraseña.
						</div>
						<div class="invalid-feedback mb-3" *ngIf=" form.controls['repeatPass'].errors?.['mustMatch']">
							Las contraseñas deben coincidir.
						</div>
						<div class="mb-3"
							*ngIf="!form.controls['repeatPass'].errors || !(form.controls['repeatPass'].dirty || form.controls['repeatPass'].touched)">
							<div>&nbsp;</div>
						</div>
					</div>

					<div class="d-flex flex-column w-100 my-4" style="gap: .5rem">
						<button type="button" class="btn btn-login shadow-sm"
							style="font-weight: bold;font-family: Helvetica, Arial, sans-serif;font-size: 16px"
							(click)="recuperarContrasena()">Cambiar Contraseña</button>
						<br>
						<button type="button" class="btn btn-back "
							style="font-weight: bold;font-family: Helvetica, Arial, sans-serif;font-size: 16px"
							(click)="iniciarSesion()">Cancelar</button>
					</div>
					<br>
					<br>
					<br>
					<br>
				</form>
			</div>
		</div>
	</div>

	<div *ngIf="pwdCambioTerminado" class="contenedor" [@crop]>
		<div class="izquierda">
			<div>
				<div class="label-intro">
					<label>Recuperación de Cuenta</label>
					<br>
					<label>Lubcricentro</label>
				</div>
				<img src="/assets/images/pwd-changed.svg" class="imagen">
				<div class="label-intro-2">
					<label></label>
				</div>
			</div>
		</div>
		<div class="derecha">
			<div class="formulario">
				<div class="logo">
					<img src="./../../../assets/system/logo_login.png" alt="Logo">
				</div>
				<div class="label-instruccion">
					<label>¡Su contraseña ha sido cambiada exitosamente!</label>
					<br>
					<label>Ya puede acceder a Lubcricentro con su nueva contraseña.</label>
				</div>

				<div class="d-flex flex-column w-100 my-4" style="gap: .5rem">
					<button type="button" class="btn btn-login shadow-sm"
						style="font-weight: bold;font-family: Helvetica, Arial, sans-serif;font-size: 16px"
						(click)="iniciarSesion()">Iniciar Sesión</button>
				</div>
				<br>
				<br>
				<br>
				<br>
			</div>
		</div>
	</div>
</div>