import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/utils/config/config.service';
import { SesionService } from 'src/app/utils/sesion/sesion.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  controller: string = "usuario/";

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private sesion: SesionService,
  ) { }

  insert(usuario: any): Observable<any> {
    return this.http.post(this.config.apiUrl + this.controller + 'crear', usuario, this.sesion.headers);
  }

  update(usuario: any): Observable<any> {
    return this.http.put(this.config.apiUrl + this.controller + 'actualizar/' + usuario.id, usuario, this.sesion.headers);
  }

  index_select(): Observable<any> {
    return this.http.get(this.config.apiUrl + this.controller + 'ver-select', this.sesion.headers);
  }

  select(): Observable<any> {
    return this.http.get(this.config.apiUrl + this.controller + 'select', this.sesion.headers);
  }

  deactivate(id: any): Observable<any> {
    return this.http.delete(this.config.apiUrl + this.controller + 'desactivar/' + id, this.sesion.headers);
  }

  activate(id: any): Observable<any> {
    return this.http.delete(this.config.apiUrl + this.controller + "activar/" + id, this.sesion.headers);
  }

  delete(id: any): Observable<any> {
    return this.http.delete(this.config.apiUrl + this.controller + "eliminar/" + id, this.sesion.headers);
  }

  index_rol_select(): Observable<any> {
    return this.http.get(this.config.apiUrl + this.controller + 'ver-rol-select', this.sesion.headers);
  }

  view(id: number): Observable<any> {
    return this.http.post(this.config.apiUrl + this.controller + "view", { id: id, token: this.sesion.token }, this.sesion.headers);
  }
}
