<div class="login-background">
	<div *ngIf="!emailEnviado" class="contenedor" [@crop]>
		<div class="izquierda">
			<div>
				<div class="label-intro">
					<label>Recuperación de Cuenta</label>
                    <br>
                    <label>Lubcricentro</label>
				</div>
				<img src="/assets/images/forgot-pwd.svg" class="imagen">
				<div class="label-intro-2">
					<label></label>
				</div>
			</div>
		</div>
		<div class="derecha">
			<div class="formulario">
				<form [formGroup]="form">
					<div class="logo">
						<img src="./../../../assets/system/logo_login.png" alt="Logo">
					</div>
					<div class="label-instruccion">
						<label>Ingrese su correo electrónico para recuperar su cuenta de Lubcricentro</label>
					</div>
					<div>
                        <label for="email" class="label-input">Correo Electrónico</label>
                        <input type="text" class="form-control" id="email" formControlName="email"
                            [ngClass]="{'is-invalid': form.controls['email'].errors && (form.controls['email'].dirty || form.controls['email'].touched)}">
                        <div class="invalid-feedback mb-3" *ngIf=" form.controls['email'].errors?.['required']">
                            Ingrese un correo electrónico asociado a su cuenta.
                        </div>
						<div class="invalid-feedback mb-3" *ngIf=" form.controls['email'].errors?.['email']">
							El correo electrónico no es válido.
						</div>
						<div class="mb-3"
							*ngIf="!form.controls['email'].errors || !(form.controls['email'].dirty || form.controls['email'].touched)">
							<div>&nbsp;</div>
						</div>
					</div>

					<div class="d-flex flex-column w-100 my-4" style="gap: .5rem">
                        <button type="button" class="btn btn-login shadow-sm" style="font-weight: bold;font-family: Helvetica, Arial, sans-serif;font-size: 16px" (click)="recuperarContrasena()">Recuperar Contraseña</button>
                        <br>
                        <button type="button" class="btn btn-back " style="font-weight: bold;font-family: Helvetica, Arial, sans-serif;font-size: 16px" (click)="iniciarSesion()">Volver</button>
					</div>
					<br>
					<br>
					<br>
					<br>
				</form>
			</div>
		</div>
	</div>

    <div *ngIf="procesoTerminado" class="contenedor" [@crop]>
		<div class="izquierda">
			<div>
				<div class="label-intro">
					<label>Recuperación de Cuenta</label>
                    <br>
                    <label>Lubcricentro</label>
				</div>
				<img src="/assets/images/mail-sent.svg" class="imagen">
				<div class="label-intro-2">
					<label></label>
				</div>
			</div>
		</div>
		<div class="derecha">
			<div class="formulario">
					<div class="logo">
						<img src="./../../../assets/system/logo_login.png" alt="Logo">
					</div>
					<div class="label-instruccion">
						<label>Hemos enviado un correo electrónico a <b>{{form.controls['email'].value}}</b> con los pasos a seguir para recuperar su contraseña.</label>
					</div>

					<div class="d-flex flex-column w-100 my-4" style="gap: .5rem">
                        <button type="button" class="btn btn-login shadow-sm" style="font-weight: bold;font-family: Helvetica, Arial, sans-serif;font-size: 16px" (click)="iniciarSesion()">Volver</button>
					</div>
					<br>
					<br>
					<br>
					<br>
			</div>
		</div>
	</div>
</div>